import React from 'react';

const styles = {
    heroContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: '20px 50px;',
        boxShadow: '0 1px 8px #ddd',
        minHeight: '250px',
        fontWeight: 'bold', 
        fontSize: 'large', 
        fontFamily: '"Helvetica", "Sans-Serif"',
    }    
}

export const Hero = () => {
    return (
        <div style={styles.heroContainer}>
            We turn ideas into reality! Together we grow.
        </div>
    )
};