import './App.css';
import { Header } from './Header/index.tsx';
import { Navbar } from './Navbar/index.tsx';
import { Hero } from './Hero/index.tsx';
import { Content } from './Content/index.tsx';
import { Footer } from './Footer/index.tsx';

function App() {
  return (
    <div className="App">
      <Header />
      <Navbar />
      <Hero />
      <Content />
      <Footer />
    </div>
  );
}

export default App;
