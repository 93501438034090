import React from 'react';

const styles = {
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: '0px 20px;',
        boxShadow: '0 2px 8px',
        fontFamily: 'arial',
        minHeight: '100px',
    }    
}

export function Footer() {
    return (
        <div style={styles.headerContainer}>
            <span style={{ fontSize: 'small', fontFamily: '"Helvetica", "Sans-Serif"'}}>All rights reserved</span>
        </div>
    )
};