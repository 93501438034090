import React from 'react';

const styles = {
    headerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px 20px;',
        boxShadow: '0 2px 8px green',
        fontFamily: 'arial',
        minHeight: '100px',
    }    
}

export function Header() {
    return (
        <div style={styles.headerContainer}>
            <span style={{ fontWeight: 'bold', fontSize: 'large', fontFamily: '"Helvetica", "Sans-Serif"'}}>BlankSpace Consulting Inc.</span>
        </div>
    )
};