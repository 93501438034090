import React from 'react';

const TEXT_STYLES = {
  paragraph: { fontWeight: 'normal' },
  emphasis: { fontWeight: 'medium' },
  display: { fontWeight: 'semibold' },
  bold: { fontWeight: 'bold' },
  headingBold: { fontFamily: 'heading', fontWeight: 'bold' },
  headingExtraBold: { fontWeight: 'extrabold' },
  thin: { fontWeight: 'thin' },
} as const;

const styles = {
    listStyle: {
        listStyle: 'none',
        fontFamily: 'arial',
        display: 'inline-block',
        padding: '0 20px',
    },
    navContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px 50px;',
        boxShadow: '0 1px 8px #ddd'
    }    
}

export const Navbar = () => {
    return (
        <nav style={styles.navContainer}>
            <ul>
                <li style={{ ...styles.listStyle, ...TEXT_STYLES.headingBold, fontSize: 'large', fontFamily: 'arial'}}>About</li>
                <li style={{ ...styles.listStyle, ...TEXT_STYLES.headingBold, fontSize: 'large', fontFamily: 'arial'}}>Services</li>
                <li style={{ ...styles.listStyle, ...TEXT_STYLES.headingBold, fontSize: 'large', fontFamily: 'arial'}}>Contact</li>
            </ul>
        </nav>
    )
};