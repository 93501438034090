import React from 'react';

const styles = {
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: '0px 20px;',
        boxShadow: '0 2px 8px',
        fontFamily: 'arial',
        minHeight: '500px',
    }    
}

export function Content() {
    return (
        <div style={styles.headerContainer}>
            <span style={{ fontWeight: 'bold', fontSize: 'large', fontFamily: '"Helvetica", "Sans-Serif"'}}>Usages & Content</span>
        </div>
    )
};